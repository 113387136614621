/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import styled from '@emotion/styled'
import { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import InputMask from 'react-input-mask'

import { useIntl } from 'gatsby-plugin-intl'
import Button from '../../components/Button'
import FlexContainer from '../../components/FlexContainer'
import Layout from '../../components/Layout'
import Spacing from '../../components/Spacing'
import Text from '../../components/Text'
import Title from '../../components/Title'
import Wrapper from '../../components/Wrapper'

import api from '../../services/api'
import { fetchAddress } from '../../services/address'
import { sendInstitutionRegistrationEmail } from '../../services/mail'

import { FormControl, FormGroupColumn } from '../../components/Form'
import Loading from '../../images/icons/loadingbutton.svg'

const Form = styled.form`
  ${({ theme }) => css`
    fieldset {
      margin: 50px 0;

      legend {
        color: ${theme.colors.celestialblue};
        font-weight: 600;
        margin-bottom: 28px;
      }
    }
    a {
      color: ${theme.colors.celestialblue};
    }
  `}
`

const Categories = styled.div`
  ${({ theme }) => css`
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 20px;
    margin-top: 40px;

    button {
      background: #ffffff;
      border: 2px solid #c1c1c1;
      color: #c1c1c1;
      border-radius: 43px;
      height: 48px;
      outline: 0;
      cursor: pointer;

      &.active {
        border-color: ${theme.colors.celestialblue};
        color: ${theme.colors.celestialblue};
        font-weight: bold;
      }
    }
  `}
`

const InstitutionRegister = () => {
  const intl = useIntl()
  const { register, handleSubmit, setValue, reset } = useForm()

  const [isLoading, setLoading] = useState(false)
  const [categories, setCategories] = useState([])
  const [selectedCategories, setSelectedCategories] = useState([])

  const loadCategories = async () => {
    try {
      const response = await api.get('/categories')
      setCategories(response.data)
    } catch (error) {
      if (error.message) toast.warn(error.message)
    }
  }

  useEffect(() => {
    loadCategories()
  }, [])

  const handleCategorySelect = name => {
    if (selectedCategories.includes(name)) {
      setSelectedCategories(
        selectedCategories.filter(category => category !== name)
      )
    } else {
      setSelectedCategories([...selectedCategories, name])
    }
  }

  const onSubmit = async ({
    nomeInstituicao,
    cnpj,
    site,
    endereco,
    cidade,
    cep,
    dddTelefone,
    numeroTelefone,
    nome,
    dddCelular,
    numeroCelular,
    email,
    cargo,
    descricao,
  }) => {
    setLoading(true)
    const isSelected = document.querySelector('input[name=aceitar]').checked

    if (!isSelected) {
      toast.warn(
        intl.formatMessage({
          id: 'toast_messages.warning.required_terms_aggrement',
        })
      )
      setLoading(false)
      return
    }

    try {
      const response = await sendInstitutionRegistrationEmail({
        nomeInstituicao,
        cnpj,
        site,
        endereco,
        cidade,
        cep,
        dddTelefone,
        numeroTelefone,
        categorias: selectedCategories,
        nome,
        dddCelular,
        numeroCelular,
        email,
        cargo,
        descricao,
      })

      if (response.data.status === 'error') {
        setLoading(false)
        toast.error(response.data.message)
        return
      }

      toast.success(intl.formatMessage({ id: 'toast_messages.success.form' }))

      reset({
        nomeInstituicao: '',
        cnpj: '',
        site: '',
        endereco: '',
        cidade: '',
        cep: '',
        dddTelefone: '',
        numeroTelefone: '',
        categorias: '',
        nome: '',
        dddCelular: '',
        numeroCelular: '',
        email: '',
        cargo: '',
        descricao: '',
      })

      setSelectedCategories([])
    } catch (error) {
      toast.error(intl.formatMessage({ id: 'toast_messages.error.email' }))
    }

    setLoading(false)
  }

  const handleZipCodeChange = async zipCode => {
    try {
      const response = await fetchAddress(zipCode.replace('-', ''))
      const { logradouro, localidade } = response.data

      setValue('endereco', logradouro)
      setValue('cidade', localidade)
    } catch (error) {
      toast.info(intl.formatMessage({ id: 'toast_messages.address_error' }))
    }
  }

  return (
    <Layout>
      <Wrapper>
        <Spacing top="40" bottom="60">
          <FlexContainer
            css={theme => css`
              ${theme.queries.medium} {
                align-items: center;
                flex-direction: row;
                justify-content: space-between;
              }
            `}
          >
            <Title as="h3" color="celestialblue">
              {intl.formatMessage({ id: 'pages.institutions_register.title' })}
            </Title>
            <Text color="gray" size="18">
              ({intl.formatMessage({ id: 'pages.institutions_register.tips' })})
            </Text>
          </FlexContainer>

          <Form onSubmit={handleSubmit(onSubmit)}>
            <fieldset>
              <legend>
                {intl.formatMessage({
                  id: 'pages.institutions_register.fieldset_1',
                })}
              </legend>
              <FormControl>
                <label htmlFor="nomeInstituicao">
                  {intl.formatMessage({
                    id: 'pages.institutions_register.fieldset_1_input_1',
                  })}
                  *
                </label>
                <input
                  required
                  type="text"
                  name="nomeInstituicao"
                  ref={register({ required: true })}
                />
              </FormControl>

              <FormGroupColumn>
                <FormControl>
                  <label htmlFor="site">
                    {intl.formatMessage({
                      id: 'pages.institutions_register.fieldset_1_input_2',
                    })}{' '}
                    *
                  </label>
                  <input
                    required
                    type="text"
                    name="site"
                    ref={register({ required: true })}
                  />
                </FormControl>
                <FormControl>
                  <label htmlFor="cnpj">
                    {intl.formatMessage({
                      id: 'pages.institutions_register.fieldset_1_input_3',
                    })}
                    *
                  </label>
                  <InputMask
                    required
                    type="text"
                    name="cnpj"
                    ref={register({ required: true })}
                    mask="99.999.999/9999-99"
                  />
                </FormControl>
              </FormGroupColumn>

              <FormControl>
                <label htmlFor="descricao">
                  {intl.formatMessage({
                    id: 'pages.institutions_register.fieldset_1_input_4',
                  })}
                  **
                </label>
                <textarea
                  required
                  type="text"
                  name="descricao"
                  ref={register({ required: true })}
                />
              </FormControl>
              <FormGroupColumn>
                <FormControl className="cep">
                  <label htmlFor="cep">
                    {intl.formatMessage({
                      id: 'pages.institutions_register.fieldset_1_input_5',
                    })}{' '}
                    *
                  </label>
                  <InputMask
                    required
                    type="text"
                    name="cep"
                    mask="99999-999"
                    ref={register({ required: true })}
                    onBlur={e => {
                      if (!e.target.value) {
                        return
                      }

                      const fullValueInArray = e.target.value
                        .split('-')
                        .join('')
                        .split('')
                      const fullValue = fullValueInArray
                        .map(item => parseInt(item))
                        .every(item => typeof item === 'number' && !isNaN(item))

                      if (!fullValue) {
                        return
                      }

                      handleZipCodeChange(e.target.value)
                    }}
                  />
                </FormControl>

                <FormControl>
                  <label htmlFor="endereco">
                    {intl.formatMessage({
                      id: 'pages.institutions_register.fieldset_1_input_6',
                    })}
                    *
                  </label>
                  <input
                    required
                    type="text"
                    name="endereco"
                    ref={register({ required: true })}
                  />
                </FormControl>
                <FormControl>
                  <label htmlFor="cidade">
                    {intl.formatMessage({
                      id: 'pages.institutions_register.fieldset_1_input_7',
                    })}{' '}
                    *
                  </label>
                  <input
                    required
                    type="text"
                    name="cidade"
                    ref={register({ required: true })}
                  />
                </FormControl>
              </FormGroupColumn>

              <FormGroupColumn>
                <FormGroupColumn>
                  <FormControl>
                    <label htmlFor="numeroTelefone">
                      {intl.formatMessage({
                        id: 'pages.institutions_register.fieldset_1_input_8',
                      })}
                      *
                    </label>
                    <InputMask
                      required
                      type="text"
                      name="numeroTelefone"
                      ref={register({ required: true })}
                      mask="(99)99999-9999"
                    />
                  </FormControl>
                </FormGroupColumn>
              </FormGroupColumn>

              <FormControl>
                <label>
                  {intl.formatMessage({
                    id: 'pages.institutions_register.fieldset_1_input_9',
                  })}
                </label>
                <Categories>
                  {categories.map(category => (
                    <button
                      key={category.id}
                      type="button"
                      onClick={() => handleCategorySelect(category.name)}
                      className={
                        selectedCategories.includes(category.name)
                          ? 'active'
                          : ''
                      }
                    >
                      {category.name}
                    </button>
                  ))}
                </Categories>
              </FormControl>
            </fieldset>

            <fieldset>
              <legend>
                {intl.formatMessage({
                  id: 'pages.institutions_register.fieldset_2',
                })}
              </legend>
              <FormControl>
                <label htmlFor="nome">
                  {intl.formatMessage({
                    id: 'pages.institutions_register.fieldset_2_input_1',
                  })}{' '}
                  *
                </label>
                <input
                  required
                  type="text"
                  name="nome"
                  ref={register({ required: true })}
                />
              </FormControl>

              <FormGroupColumn>
                <FormControl>
                  <label htmlFor="numeroCelular">
                    {intl.formatMessage({
                      id: 'pages.institutions_register.fieldset_2_input_2',
                    })}{' '}
                    *
                  </label>
                  <input
                    required
                    type="text"
                    name="numeroCelular"
                    ref={register({ required: true })}
                  />
                </FormControl>
                <FormControl>
                  <label htmlFor="email">
                    {intl.formatMessage({
                      id: 'pages.institutions_register.fieldset_2_input_3',
                    })}{' '}
                    *
                  </label>
                  <input
                    required
                    type="text"
                    name="email"
                    ref={register({ required: true })}
                  />
                </FormControl>
                <FormControl>
                  <label htmlFor="cargo">
                    {intl.formatMessage({
                      id: 'pages.institutions_register.fieldset_2_input_4',
                    })}{' '}
                    *
                  </label>
                  <input
                    required
                    type="text"
                    name="cargo"
                    ref={register({ required: true })}
                  />
                </FormControl>
              </FormGroupColumn>
            </fieldset>

            <FlexContainer
              css={theme => css`
                ${theme.queries.medium} {
                  align-items: baseline;
                  flex-direction: row;
                  justify-content: space-between;
                }
              `}
            >
              <FormControl
                css={css`
                  align-items: center;
                  display: flex;
                  flex-direction: row-reverse;
                  width: fit-content;

                  label,
                  input {
                    margin: 0;
                  }

                  label {
                    margin-left: 12px;
                  }
                `}
              >
                <label
                  htmlFor="aceitar"
                  css={css`
                    position: relative !important;
                  `}
                >
                  {intl.formatMessage({
                    id: 'pages.institutions_register.privacy_n_terms_1',
                  })}{' '}
                  <a href="/termos" target="blank">
                    {intl.formatMessage({
                      id: 'pages.institutions_register.privacy_n_terms_2',
                    })}
                  </a>{' '}
                  e{' '}
                  <a href="/privacidade">
                    {intl.formatMessage({
                      id: 'pages.institutions_register.privacy_n_terms_3',
                    })}
                  </a>
                </label>
                <input
                  type="checkbox"
                  name="aceitar"
                  css={css`
                    cursor: pointer;
                  `}
                />
              </FormControl>

              <Button
                type="submit"
                whileHover={{ scale: 1.03 }}
                whileTap={{ scale: 0.97 }}
                uppercase
                css={css`
                  width: 281px;
                  height: 55px;
                `}
              >
                {isLoading ? (
                  <Loading />
                ) : (
                  intl.formatMessage({
                    id: 'pages.institutions_register.button',
                  })
                )}
              </Button>
            </FlexContainer>
          </Form>
        </Spacing>
      </Wrapper>
    </Layout>
  )
}

export default InstitutionRegister
