import axios from 'axios'
import api from './api'

const localService = axios.create({
  baseURL: `https://denario.azurewebsites.net`,
})

localService.interceptors.request.use(config => {
  config.headers['Allow-Cross-Origin'] = '*'
  config.headers['Access-Control-Allow-Origin'] = '*'
  config.headers['Access-Control-Allow-Methods'] =
    'GET, PUT, POST, DELETE, OPTIONS'
  config.headers['Access-Control-Allow-Headers'] =
    'Origin, X-Requested-With, Content-Type, Accept'
  config.headers['Content-Type'] = 'application/x-www-form-urlencoded'

  return config
})

export async function sendContactEmail({ nome, doador, email, mensagem }) {
  const bodyFormData = new FormData()
  bodyFormData.set('nome', nome)
  bodyFormData.set('doador', doador === 'on' ? doador : 'off')
  bodyFormData.set('email', email)
  bodyFormData.set('mensagem', mensagem)

  return localService.post(`/contato.php`, bodyFormData)
}

export async function sendInstitutionRegistrationEmail({
  nomeInstituicao,
  cnpj,
  site,
  endereco,
  cidade,
  cep,
  numeroTelefone,
  categorias,
  nome,
  numeroCelular,
  email,
  cargo,
  descricao,
}) {
  return api.post(`/institutions/mail/register`, {
    institutionName: nomeInstituicao,
    description: descricao,
    cnpj,
    site,
    address: endereco,
    city: cidade,
    zipCode: cep,
    phone: numeroTelefone,
    categories: categorias,
    contact: {
      name: nome,
      phone: numeroCelular,
      email,
      function: cargo,
    },
  })
}
